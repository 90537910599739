// Import Packages
import * as React from "react"

// Import Styles
import "../css/style.css"

// Import Templates
import { PageTitle, Header, Footer } from "../templates/Components"

// Import Assets
import Headshot from "../images/headshot.png"

export default function AboutPage ({ location }) {
  return (
    <div>
      <PageTitle pageTitle="About | "/>
      <Header/>
      <div id="contents">
        <div className="clearfix">
          <div className="sidebar">
            <div className="box">
              <img src={Headshot} alt="Dominic Rizzi in front of the Tucson Skyline" height="300" width="300"/>
            </div>
          {/* <!-- </div> --> */}
          {/* <!-- <div className="sidebar">
            <div>
              <h2>Our Lawyers</h2>
              <p>
                This website template has been designed by Free Website Templates for you, for free. You can replace all this text with your own text.
              </p>
              <p>
                You can remove any link to our website from this website template, you're free to use this website template without linking back to us.
              </p>
              <p>
                If you're having problems editing this website template, then don't hesitate to ask for help on the Forums.
              </p>
            </div> --> */}
            <div>
              {/* <!-- <h2>Client Testimonials</h2>
              <p>
                &ldquo;The best family lawyers in the city so far. Me and my ex-wife didn’t have any<br> problems settling the terms and agreement. Everything went so smoothly. We’re both very happy.&ldquo; <span>- Jared Greene</span>
              </p> --> */}
            </div>
          </div>
          <div className="about">
            <h1>About</h1>
            <p>
              As a Tucson native, Dominic Rizzi is dedicated to serving the community he grew up in. He graduated from the University of Arizona in 2013 with a Bachelor’s Degree in Public Policy and Administration. In 2016, he obtained his law degree from the University of Arizona James E. Rogers College of Law. During his time in law school, he interned with the Tucson Police Department’s Legal Advisor’s Office. There, he worked on a wide variety of legal issues that included search and seizure, use of force, and employment law. After graduating, Mr. Rizzi worked as a prosecutor for nearly five years. During his time as a prosecutor, he managed hundreds of criminal cases and conducted countless jury trials at the misdemeanor and felony level. Now, Mr. Rizzi is here to bring his experience and skills to assist you. At the Law Office of Dominic Rizzi we are committed to excellence and committed to you.
            </p>
            <p>
              Se habla español.
            </p>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}